import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import PublicLayout from '../../layouts/PublicLayout/PublicLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import PinkAuthLayout from '../../layouts/AuthLayout/PinkAuthLayout';
import Loader from '../../helper/loaders';

const Login = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signin/Signin')
);

const SignUp = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signup/Signup')
);

const ChargeMyCard = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Auth/ChargeMyCard')
);

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const TimeZone = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Timezone/Timezone')
);

const TermsOfUse = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/TermsOfUse/TermsOfUse')
);

const GetNumber = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/GetNumber/GetNumber')
);

const ForgotPassword = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Password/ForgotPassword')
);

const ResetPassword = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Password/ResetPassword')
);

const VerifyAccount = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signin/VerifyAccount')
);

const Provider = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Provider/Provider')
);

const PinkSignUp = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signup/PinkSignUp')
);

const PinkChargeMyCard = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Auth/PinkChargeMyCard')
);

const PinkTimeZone = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Timezone/PinkTimezone')
);

const PinkTermsOfUse = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/TermsOfUse/PinkTermsOfUse')
);

const PinkGetNumber = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/GetNumber/PinkGetNumber')
);

const PinkVerifyAccount = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signin/PinkVerifyAccount')
);


function AuthComponent(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact={true}
                    path={`${match.path}/charge-my-card`}
                    component={ChargeMyCard}
                    requireAuth={auth}
                    layout={AuthLayout}
                    type='private'
                    title='Auth | Charge My Card'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/timezone`}
                    component={TimeZone}
                    requireAuth={auth}
                    layout={AuthLayout}
                    type='private'
                    title='Auth | Timezone'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/terms-of-use`}
                    component={TermsOfUse}
                    requireAuth={auth}
                    layout={AuthLayout}
                    type='private'
                    title='Auth | Terms Of Use'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/get-number`}
                    component={GetNumber}
                    requireAuth={auth}
                    layout={AuthLayout}
                    type='private'
                    title='Auth | Get Number'
                />

                <AppRoute
                    //exact={true}
                    path={`${match.path}/login:sessexpire?`}
                    component={Login}
                    layout={PublicLayout}
                    type='public'
                    title='Login'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/signup`}
                    component={SignUp}
                    layout={PublicLayout}
                    type='public'
                    title='Signup'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/forgot-password`}
                    component={ForgotPassword}
                    layout={PublicLayout}
                    type='public'
                    title='Forgot Password'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/reset-password/:email/:token`}
                    component={ResetPassword}
                    layout={PublicLayout}
                    type='public'
                    title='Reset Password'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/verify-account/:codeSentTo/:usrID`}
                    component={VerifyAccount}
                    layout={PublicLayout}
                    type='public'
                    title='Verify Account'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/provider`}
                    component={Provider}
                    requireAuth={auth}
                    layout={AuthLayout}
                    type='private'
                    title='Auth | Provider'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/pink-charge-my-card`}
                    component={PinkChargeMyCard}
                    requireAuth={auth}
                    layout={PinkAuthLayout}
                    type='private'
                    title='Auth | Charge My Card'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/pink-timezone`}
                    component={PinkTimeZone}
                    requireAuth={auth}
                    layout={PinkAuthLayout}
                    type='private'
                    title='Auth | Timezone'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/pink-terms-of-use`}
                    component={PinkTermsOfUse}
                    requireAuth={auth}
                    layout={PinkAuthLayout}
                    type='private'
                    title='Auth | Terms Of Use'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/pink-get-number`}
                    component={PinkGetNumber}
                    requireAuth={auth}
                    layout={PinkAuthLayout}
                    type='private'
                    title='Auth | Get Number'
                />


                <AppRoute
                    exact={true}
                    path={`${match.path}/pinksignup`}
                    component={PinkSignUp}
                    layout={PublicLayout}
                    type='public'
                    title='Signup'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/pink-verify-account`}
                    component={PinkVerifyAccount}
                    requireAuth={auth}
                    layout={PinkAuthLayout}
                    type='private'
                    title='Pink Verify Account'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
                
                <AppRoute
                    exact
                    path={`${match.path}`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
                
            </Switch>
        </React.Fragment>
    )
}

export default AuthComponent
