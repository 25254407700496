import React, { useState, useContext} from 'react';
import Span from "@material-ui/core/Box"
import { withStyles, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardIcon from '@material-ui/icons/Dashboard';
import { faUsers, faAddressBook, faPaperPlane, faShieldAlt, faFileAlt, faUserShield, faVideo} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import TemplateSubmenu from './TemplateSubmenu';
import GroupSubMenu from './GroupsSubmenu';
import ContactsSubmenu from './ContactsSubmenu';
import AdvanceSubmenu from './AdvanceSubmenu';
import CampaignSubMenu from './CampaignSubmenu';
import AdminSubMenu from './AdminSubmenu';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { UserAccountContext } from '../../contextHooks';

const styles = () => ({
    ListItemCss: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3 },
    ListItemCssActive: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3, background:'#fff', borderLeft:'3px solid #fb6e8a', '&:hover':{background:'#fff'}},
    ListContainer: { textAlign: 'center' },
    ListItemIconCss: { minWidth: 0, fontSize: 30, color: '#fff' },
    ListItemIconCssVideo:{minWidth: 0, fontSize: 27, color: '#fff' },
    ListItemTextCss: { color: '#fff', fontSize: 11 },
    ListItemTextToolsCss: { color: '#fff', fontSize: 11, marginTop: 6 },
    ListItemIconCssActive:{color:'#fb6e8a', minWidth: 0, fontSize: 30},
    ListItemTextCssActive:{ color: '#fb6e8a', fontSize: 11 },
    ListItemCssClicked: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3, background:'#f5f1f1', borderLeft:'3px solid #fb6e8a', '&:hover':{background:'#f5f1f1'}},
    ImMsgIcon:{fontSize:33},
    LinkCss: { color: '#fb6e8a', textDecoration: "none",  },
});

const SideNavigationsMenu = (props) => {
    const userAccount = useContext(UserAccountContext);
    //console.log(userAccount);
    const groupsItems = ['create-public-group', 'create-sync-group', 'manage-public-groups', 'manage-sync-groups', 'subscribe-form', 'edit-subscribe-form'];
    const templateItems = [
        'add-normal-folder', 'manage-normal-folder', 'add-normal-category', 'manage-normal-category', 'edit-normal-template', 
        'add-normal-template', 'add-drip-template', 'manage-normal-template', 'manage-drip-template', 'edit-drip-template', 
        'add-drip-folder', 'manage-drip-folder', 'add-drip-category', 'manage-drip-category', 'manage-birthdays', 'manage-users','overdue-users'
    ];
    const contactItems = [
        'add-new-contact', 'upload-contacts', 'intouch-settings', 'intouch-trigger-settings', 'manage-contacts', 'contact-info', 'edit-contact', 'group-details',
        'manage-keywords', 'create-keyword', 'edit-keyword', 'keyword-info', 'unsubscribed', 'all-responses', 'import-contacts', 'slack',
        'import-unsubscribe', 'update-contacts', 'complete-update-contacts','download-contacts','vipecloud-settings','coreg-settings', 'unsubscribe-vipecloud',
        'dnc-denied-phones','shortlink-settings','shortlink-analytics'
    ];
    const advanceItems = [
        'create-normal-template', 'create-drip-template', 'manage-normal-templates', 'manage-drip-templates', 'edit-normal-template', 'edit-drip-template', 
        'billing', 'info', 'manage-voicemails', 'record-a-voicemail', 'full-billing-history','manage-numbers', 'qrcode'
    ];

    const campaignsItems = [
        'create-campaign', 'scheduled-campaigns', 'campaign-detail', 'sent-campaigns', 'edit-campaign', 'create-campaign-v3',
        'normal-campaign-detail', 'drip-campaign-detail', 'calendar', 'resend', 'initiate-campaign', 'finish-campaign', 'resendv3','create-campaign-vs'
    ];

    const adminItems = ['manage-users', 'edit-user', 'manage-banner', 'swap-requests', 'search-contacts',
        'process-swap', 'user-dashboard', 'active-users', 'manage-pendings', 'duplicate-invoices', 'manage-a2p', 'add-foreign-contact'];

    const [subNavSelected,setSubNavSelected] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);

    const openSubNavigation = (subNavItem) =>{
        setSubNavSelected(subNavItem);
        setClickedItem(subNavItem);
    }
    const closesSubNavgation = () =>{
        setSubNavSelected(null);
        setClickedItem(null);
    }
    const { classes, pageUri } = props;
    let pageSplit = pageUri.split('/');
    let rootPage = pageSplit[1];
    let isIMPage = pageSplit[2];
    let isWizardPage = pageSplit[2];
    let settingpage = pageSplit[2];
    let groupPageIndex = groupsItems.indexOf(pageSplit[2]);
    let templatePageIndex = templateItems.indexOf(pageSplit[2]);
    let contactPageIndex = contactItems.indexOf(pageSplit[2]);
    let advanceIndex = advanceItems.indexOf(pageSplit[2]);
    let campaignIndex = campaignsItems.indexOf(pageSplit[2]);
    let adminIndex = adminItems.indexOf(pageSplit[2]);

    return (
        <div className={classes.ListContainer}>
            <List component='div' disablePadding>
            {userAccount.usr_role_id === '4' &&
            <ListItem 
                    button disableGutters 
                    component={NavLink} to="/profile/wizard"
                    //classes={{button:classes.ListItemCss}}
                    className={
                        ((rootPage === 'wizard' || rootPage === 'profile') && isWizardPage === 'wizard') 
                        ? classes.ListItemCssActive 
                        : classes.ListItemCss
                    }
                >
                    <ListItemIcon 
                        className={
                            ((rootPage === 'wizard' || rootPage === 'profile') && isWizardPage === 'wizard') 
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <DashboardIcon className={classes.ImMsgIcon}/>
                    </ListItemIcon>
                    <ListItemText 
                        primary='Wizard'
                        classes={
                            ((rootPage === 'wizard' || rootPage === 'profile') && isWizardPage === 'wizard') 
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                    />
                </ListItem>}
                <ListItem 
                    button disableGutters 
                    className={
                        (clickedItem === 'campaigns') ? classes.ListItemCssClicked :
                        (rootPage === 'campaigns' && campaignIndex >= 0) 
                        ? classes.ListItemCssActive
                        : classes.ListItemCss
                    }
                    onClick={()=>{openSubNavigation('campaigns')}}
                >
                    <ListItemIcon 
                        className={
                            ((rootPage === 'campaigns' && campaignIndex >= 0) || (clickedItem === 'campaigns')) 
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </ListItemIcon>
                    <ListItemText 
                        classes={
                            ((rootPage === 'campaigns' && campaignIndex >= 0) || (clickedItem === 'campaigns')) 
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                        primary='Campaigns' 
                    />
                </ListItem>
                <CampaignSubMenu 
                    openState = {(subNavSelected === 'campaigns') ? true : false}
                    closeSubNavigation = {()=>{closesSubNavgation()}}
                />

                <ListItem 
                    button disableGutters 
                    className={
                        (clickedItem === 'group') ? classes.ListItemCssClicked :
                        (rootPage === 'contacts' && groupPageIndex >= 0) 
                        ? classes.ListItemCssActive
                        : classes.ListItemCss
                    }
                    onClick={()=>{openSubNavigation('group')}}
                >
                    <ListItemIcon 
                        className={
                            ((rootPage === 'contacts' && groupPageIndex >= 0) || (clickedItem === 'group')) 
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <FontAwesomeIcon icon={faUsers} />
                    </ListItemIcon>
                    <ListItemText 
                        classes={
                            ((rootPage === 'contacts' && groupPageIndex >= 0) || (clickedItem === 'group')) 
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                        primary='Groups' 
                    />
                </ListItem>
                <GroupSubMenu 
                    openState = {(subNavSelected === 'group') ? true : false}
                    closeSubNavigation = {()=>{closesSubNavgation()}}
                />

                <ListItem 
                    button 
                    disableGutters 
                    className={
                        (clickedItem === 'contacts') ? classes.ListItemCssClicked :
                        (rootPage === 'contacts' && contactPageIndex >= 0) 
                        ? classes.ListItemCssActive
                        : classes.ListItemCss
                    }
                    onClick={()=>{openSubNavigation('contacts')}}
                >
                    <ListItemIcon 
                        className={
                            ((rootPage === 'contacts' && contactPageIndex >= 0) || (clickedItem === 'contacts')) 
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <FontAwesomeIcon icon={faAddressBook} />
                    </ListItemIcon>
                    <ListItemText 
                        classes={
                            ((rootPage === 'contacts' && contactPageIndex >= 0) || (clickedItem === 'contacts')) 
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                        primary='Contacts' 
                    />
                </ListItem>
                <ContactsSubmenu 
                    openState = {(subNavSelected === 'contacts') ? true : false}
                    closeSubNavigation = {()=>{closesSubNavgation()}}
                />
                    
                {/* <ListItem 
                    button disableGutters classes={{ button: classes.ListItemCss }}
                    component={NavLink} to="/activity" activeClassName="Mui-selected" exact
                >
                    <ListItemIcon classes={{ root: classes.ListItemIconCss }}>
                        <FontAwesomeIcon icon={faChartLine} />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.ListItemTextCss }} primary='Activity' />
                </ListItem> */}
                <ListItem 
                    button disableGutters 
                    component={NavLink} to="/contacts/im-messages"
                    //classes={{button:classes.ListItemCss}}
                    className={
                        (rootPage === 'contacts' && isIMPage === 'im-messages') 
                        ? classes.ListItemCssActive 
                        : classes.ListItemCss
                    }
                >
                    <ListItemIcon 
                        className={
                            (rootPage === 'contacts' && isIMPage === 'im-messages') 
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <ChatBubbleIcon className={classes.ImMsgIcon}/>
                    </ListItemIcon>
                    <ListItemText 
                        primary='Messages'
                        classes={
                            (rootPage === 'contacts' && isIMPage === 'im-messages') 
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                    />
                </ListItem>
                {/* <ListItem 
                    button disableGutters classes={{ button: classes.ListItemCss }}
                    component={NavLink} to="/tools" activeClassName="Mui-selected" exact
                >
                    <ListItemIcon classes={{ root: classes.ListItemIconCss }}>
                        <FontAwesomeIcon icon={faTools} />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.ListItemTextToolsCss }} primary='Tools' />
                </ListItem> */}
                <ListItem 
                    button 
                    disableGutters 
                    className={
                        (clickedItem === 'advance') ? classes.ListItemCssClicked :
                        ((rootPage === 'advance' || rootPage === 'profile') && (advanceIndex >= 0)) 
                        ? classes.ListItemCssActive 
                        : classes.ListItemCss
                    }
                    onClick={()=>{openSubNavigation('advance')}}
                >
                    <ListItemIcon 
                        className={
                            (((rootPage === 'advance' || rootPage === 'profile') && (advanceIndex >= 0)) || (clickedItem === 'advance'))
                            ? classes.ListItemIconCssActive
                            : classes.ListItemIconCss
                        }
                    >
                        <FontAwesomeIcon icon={faShieldAlt} />
                    </ListItemIcon>
                    <ListItemText 
                        primary='Advanced'
                        classes={
                            (((rootPage === 'advance' || rootPage === 'profile') && (advanceIndex >= 0)) || (clickedItem === 'advance'))
                            ? {primary: classes.ListItemTextCssActive} 
                            : {primary: classes.ListItemTextCss}
                        }
                    />
                </ListItem>
                <AdvanceSubmenu 
                    openState = {(subNavSelected === 'advance') ? true : false}
                    closeSubNavigation = {()=>{closesSubNavgation()}}
                />

                {userAccount.usr_role_id === '4' &&
                <Span>
                    <a href='mailto:support@betwext.com' className={classes.LinkCss} rel="noreferrer">
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'videos') ? classes.ListItemCssClicked :
                                (settingpage === 'videos') ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                        >
                            <ListItemIcon 
                            className={
                                (settingpage === 'videos')
                                    ? classes.ListItemIconCssActive
                                    : classes.ListItemIconCssVideo
                            }
                            >
                                <FontAwesomeIcon icon={faVideo} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (settingpage === 'videos')
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Training Videos'
                            />
                        </ListItem>
                    </a>
                </Span>}

                {
                    (userAccount && (userAccount.usr_role_id === '2' || userAccount.usr_role_id === '3')) &&
                    <span>
                    <ListItem 
                        button 
                        disableGutters 
                        className={
                            (clickedItem === 'template') ? classes.ListItemCssClicked :
                            (rootPage === 'manager' && templatePageIndex >= 0) 
                            ? classes.ListItemCssActive
                            : classes.ListItemCss
                        }
                        onClick={()=>{openSubNavigation('template')}}
                    >
                        <ListItemIcon 
                            className={
                                ((rootPage === 'manager' && templatePageIndex >= 0) || (clickedItem === 'template'))
                                ? classes.ListItemIconCssActive
                                : classes.ListItemIconCss
                            }
                        >
                        <FontAwesomeIcon icon={faFileAlt} />
                        </ListItemIcon>
                        <ListItemText 
                            primary='Manager'
                            classes={
                                ((rootPage === 'manager' && templatePageIndex >= 0) || (clickedItem === 'template')) 
                                ? {primary: classes.ListItemTextCssActive} 
                                : {primary: classes.ListItemTextCss}
                            }
                        />
                    </ListItem>
                    <TemplateSubmenu 
                        openState = {(subNavSelected === 'template') ? true : false}
                        closeSubNavigation = {()=>{closesSubNavgation()}}
                    />
                    </span>
                }
                {
                    (userAccount && userAccount.usr_role_id === '2') &&
                    <span>
                    <ListItem 
                        button 
                        disableGutters 
                        className={
                            (clickedItem === 'admin') ? classes.ListItemCssClicked :
                            (rootPage === 'admin' && adminIndex >= 0) 
                            ? classes.ListItemCssActive
                            : classes.ListItemCss
                        }
                        onClick={()=>{openSubNavigation('admin')}}
                    >
                        <ListItemIcon 
                            className={
                                ((rootPage === 'admin' && adminIndex >= 0) || (clickedItem === 'admin'))
                                ? classes.ListItemIconCssActive
                                : classes.ListItemIconCss
                            }
                        >
                        <FontAwesomeIcon icon={faUserShield} />
                        </ListItemIcon>
                        <ListItemText 
                            primary='Admin'
                            classes={
                                ((rootPage === 'admin' && adminIndex >= 0) || (clickedItem === 'admin')) 
                                ? {primary: classes.ListItemTextCssActive} 
                                : {primary: classes.ListItemTextCss}
                            }
                        />
                    </ListItem>
                    <AdminSubMenu 
                        openState = {(subNavSelected === 'admin') ? true : false}
                        closeSubNavigation = {()=>{closesSubNavgation()}}
                    />
                    </span>
                }
            </List>
        </div>
    );
}

export default withStyles(styles)(SideNavigationsMenu);