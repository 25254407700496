import React from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog, faFlag, faSearch, faUserAlt} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
    drawerPaper:{marginTop:62, background:'#f5f1f1', width:262, boxShadow:'none', borderRight:'none', marginLeft:70},
    ListAvatar:{background:'#fb6e8a', color:'#fff', fontSize:14, width:30, height:30},
    ListIconContainer:{minWidth:36},
    ListTitle:{fontSize:14},
    DrawerModel:{position:'relative !important', zIndex:'1200 !important'},
   
    [theme.breakpoints.only('xs')]: {
        DrawerModel:{position:'relative !important', zIndex:'1300 !important'},
    },
})

const AdminSubmenu = (props) => {
    const {classes, openState, closeSubNavigation} = props;
    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{paperAnchorLeft:classes.drawerPaper, modal:classes.DrawerModel}}
                BackdropProps={{style : {top:'62px', left:70}}}
            >
                <List component="nav" disablePadding>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/manage-users"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faUserCog} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Users" />
                    </ListItem>

                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/manage-banner"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFlag} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Banner" />
                    </ListItem>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/swap-requests"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFlag} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Swap Request" />
                    </ListItem>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/manage-pendings"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFlag} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Pending Users" />
                    </ListItem>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/duplicate-invoices"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFlag} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Duplicates" />
                    </ListItem>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/search-contacts"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faSearch} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Search Contacts" />
                    </ListItem>

                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/admin/manage-a2p"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faUserAlt} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="A2P Users" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(AdminSubmenu);