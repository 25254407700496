import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';
import publicLayout from '../../layouts/PublicLayout/PublicLayout';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const Billing = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/Billing')
);

const Voicemails = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/Voicemails')
);

const RecordAVoicemail = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/RecordVoicemails')
);

const Info = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/Info')
);

const FullBillingHistory = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/FullBillingHistory')
);

const Receipt = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/InvoiceReceipt')
);

const ManageNumbers = Loader(() => 
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/ManageNumbers')
);

const Wizard = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Wizard/Wizard')
);

const QRCode = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/ProfileQRCode')
);

function ProfileRoutes(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact={true}
                    path={`${match.path}/info`}
                    component={Info}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | Info'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/billing`}
                    component={Billing}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | Billing'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-voicemails`}
                    component={Voicemails}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | Manage Voicemail'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/record-a-voicemail`}
                    component={RecordAVoicemail}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | Record A Voicemail'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/full-billing-history`}
                    component={FullBillingHistory}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Billing | Full Billing History'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/invoice-receipt/:id`}
                    component={Receipt}
                    requireAuth={auth}
                    layout={publicLayout}
                    type='public'
                    title='Billing | Receipt'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-numbers`}
                    component={ManageNumbers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Billing | Manage Numbers'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/wizard`}
                    component={Wizard}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | Wizard'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/qrcode`}
                    component={QRCode}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Profile | QR Code'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>
    )
}

export default ProfileRoutes;