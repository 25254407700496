import React, { useContext } from 'react'
import {Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCalendarAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { UserAccountContext } from '../../contextHooks';
import Span  from "@material-ui/core/Box";

const styles = (theme) => ({
    drawerPaper:{marginTop:62, background:'#f5f1f1', width:262, boxShadow:'none', borderRight:'none', marginLeft:70},
    ListItemPadding:{paddingLeft:17, paddingRight:13},
    ListAvatar:{background:'#fb6e8a', color:'#fff', fontSize:14, width:30, height:30},
    ListIconContainer:{minWidth:36},
    ListTitle:{fontSize:14},
    DrawerModel:{position:'relative !important', zIndex:'1200 !important'},
    [theme.breakpoints.only('xs')]: {
        DrawerModel:{position:'relative !important', zIndex:'1300 !important'},
    },
})

const CampaignSubmenu = (props) => {
    const {classes, openState, closeSubNavigation} = props;
    const userAccount = useContext(UserAccountContext);

    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{paperAnchorLeft:classes.drawerPaper, modal:classes.DrawerModel}}
                BackdropProps={{style : {top:'62px', left:70}}}
            >
                <List component="nav" disablePadding>
                {(userAccount.usr_role_id !== '4') &&
                <Span>
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/create-campaign"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Create Campaign" />
                    </ListItem>
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/initiate-campaign"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Create Campaign V2" />
                    </ListItem>
                </Span>
                }
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/create-campaign-v3"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Create Campaign V3" />
                    </ListItem>
                    {(userAccount.usr_role_id === '2' || userAccount.usr_role_id === '4') &&
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/create-campaign-vs"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Create Campaign (Analytics)" />
                    </ListItem>
                    }
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/scheduled-campaigns"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Scheduled Campaigns" />
                    </ListItem>
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/sent-campaigns"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Sent Campaigns" />
                    </ListItem>
                    <ListItem 
                        button 
                        classes={{gutters:classes.ListItemPadding}}
                        component={NavLink}
                        to="/campaigns/calendar"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Calendar" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(CampaignSubmenu);
