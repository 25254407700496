import React from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Collapse, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faStickyNote, faVoicemail, faRecordVinyl, faFileInvoiceDollar, faUser, faQrcode} from '@fortawesome/free-solid-svg-icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//import DialpadIcon from '@material-ui/icons/Dialpad';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
    drawerPaper:{marginTop:62, background:'#f5f1f1', width:272, boxShadow:'none', borderRight:'none', marginLeft:70},
    ListItemPadding:{paddingLeft:17, paddingRight:13},
    ListAvatar:{background:'#fb6e8a', color:'#fff', fontSize:14, width:30, height:30},
    ListIconContainer:{minWidth:36},
    ListTitle:{fontSize:14},
    ListSubmenuIconArrow:{minWidth:19, marginLeft:37},
    SublistTitles:{fontSize:13},
    SublistContainerPadding:{paddingTop:4, paddingBottom:4},
    DialpadIconCss:{fontSize:16},
    DrawerModel:{position:'relative !important', zIndex:'1200 !important'},
    [theme.breakpoints.only('xs')]: {
        DrawerModel:{position:'relative !important', zIndex:'1300 !important'},
    },
})

const TemplateSubmenu = (props) => {
    const {classes, openState, closeSubNavigation} = props;

    const [advanceSubNav, setAdvanceSubNav] = React.useState(false);

    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{paperAnchorLeft:classes.drawerPaper, modal:classes.DrawerModel}}
                BackdropProps={{style : {top:'62px', left:70}}}
            >
                <List component="nav" disablePadding>
                    {/* Manage Normal Templates */}
                    <ListItem button onClick={()=>{setAdvanceSubNav(!advanceSubNav)}} classes={{gutters:classes.ListItemPadding}}>
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faStickyNote} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Templates" />
                        {advanceSubNav ? <ArrowDropDownIcon /> : < ArrowRightIcon/>}
                    </ListItem>
                    <Collapse in={advanceSubNav} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem 
                                button className={classes.SublistContainerPadding}
                                component={NavLink} to="/advance/create-normal-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Custom Template" />
                            </ListItem>
                            <ListItem 
                                button className={classes.SublistContainerPadding}
                                component={NavLink} to="/advance/manage-normal-templates"
                                onClick={()=>{closeSubNavigation()}}
                                >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Custom Templates" />
                            </ListItem>
                            <ListItem 
                                button className={classes.SublistContainerPadding}
                                component={NavLink} to="/advance/create-drip-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Custom Drip Template" />
                            </ListItem>
                            <ListItem 
                                button className={classes.SublistContainerPadding}
                                component={NavLink} to="/advance/manage-drip-templates"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Custom Drip Template" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/manage-voicemails"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faVoicemail} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Voicemail Settings" />
                    </ListItem>
                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/record-a-voicemail"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faRecordVinyl} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Voicemail Record" />
                    </ListItem>
                    {/* <ListItem button  classes={{gutters:classes.ListItemPadding}}>
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <DialpadIcon className={classes.DialpadIconCss}/>
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Dialpad" />
                    </ListItem> */}
                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/billing"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Billing" />
                    </ListItem>
                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/info"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faUser} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Profile" />
                    </ListItem>
                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/manage-numbers"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Numbers" />
                    </ListItem>

                    <ListItem 
                        button  classes={{gutters:classes.ListItemPadding}}
                        component={NavLink} to="/profile/qrcode"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faQrcode} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="View QR Code" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(TemplateSubmenu);
