export const Regex_Validator = {
    String: /^[a-zA-Z ]+$/,
    Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PhoneNumber: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    Number: /^[0-9\b]+$/,
    NotNumber: /\D/,
    NotString: /[0-9~!@#$%^&*+(|?[)._}{'"[\]\\:;/><,-=`]/,
    CvvCode: /^[0-9]{3,4}$/,
    CardNumber: /^[1-9][0-9]{14,15}$/,
    ZipCode: /^[0-9]{5,6}$/,
    ImageType: /\.(jpe?g|png|gif|bmp)$/i,
    AudioVideoType: /\.(mp3|mp4|avi|wmv|mpe?g|3gp|ogg|webm|wav)$/i,
    AudioType: /\.(mp3|wmv|ogg|webm|wav)$/i,
    VideoType: /\.(mp4|avi|mpe?g|3gp)$/i,
    CsvXlsxFileType: /.*\.(xlsx|xls|csv)/g
}

export const Payment_Transaction_Type = {
    subscription: "S", 
    regular: "R"
}

export const Auth_Phase_Redirection = {
    REGISTER: '/auth/charge-my-card',
    CHARGECARD: '/auth/charge-my-card',
    CONFIRMPAY: '/auth/provider',
    PROVIDER:'/auth/timezone',
    TIMEZONE: '/auth/terms-of-use',
    TERM: '/auth/get-number',
    GETNUMBER: '/campaigns/create-campaign',
    COMPLETED: '/campaigns/create-campaign',
}

export const Pink_Auth_Phase_Redirection = {
    REGISTER: '/auth/pink-charge-my-card',
    CHARGECARD: '/auth/pink-charge-my-card',
    CONFIRMPAY: '/auth/pink-timezone',
    TIMEZONE: '/auth/pink-verify-account',
    TWOFACTOR: '/auth/pink-get-number',
    // VERIFYACCOUNT: '/auth/pink-get-number',
    GETNUMBER: '/profile/wizard',
    WIZARD: '/profile/wizard',
    COMPLETED: '/campaigns/create-campaign',
}

export const UserRoleAuthRoute = {
    manager: [2, 3],
    admin: [2],
}
