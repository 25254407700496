import React, { useState, useContext } from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Collapse, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faAddressBook, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { NavLink } from 'react-router-dom';
import { UserAccountContext } from '../../contextHooks';

const styles = (theme) => ({
    drawerPaper: { marginTop: 62, background: '#f5f1f1', width: 262, boxShadow: 'none', borderRight: 'none', marginLeft: 70 },
    ListItemPadding: { paddingLeft: 17, paddingRight: 13 },
    ListAvatar: { background: '#fb6e8a', color: '#fff', fontSize: 14, width: 30, height: 30 },
    ListIconContainer: { minWidth: 36 },
    ListTitle: { fontSize: 14 },
    ListSubmenuIconArrow: { minWidth: 19, marginLeft: 37 },
    SublistTitles: { fontSize: 13 },
    SublistContainerPadding: { paddingTop: 4, paddingBottom: 4 },
    DrawerModel: { position: 'relative !important', zIndex: '1200 !important' },
    [theme.breakpoints.only('xs')]: {
        DrawerModel: { position: 'relative !important', zIndex: '1300 !important' },
    },
})

const ContactSubmenu = (props) => {
    const { classes, openState, closeSubNavigation } = props;
    const userAccount = useContext(UserAccountContext);

    const [allContacts, setAllContacts] = useState(false);
    const handleAllContacts = () => { setAllContacts(!allContacts) }

    const [advanceContact, setAdvanceContact] = useState(false);
    const handleAdvance = () => { setAdvanceContact(!advanceContact) }

    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{ paperAnchorLeft: classes.drawerPaper, modal: classes.DrawerModel }}
                BackdropProps={{ style: { top: '62px', left: 70 } }}

            >
                <List component="nav" disablePadding>
                    {/* All Contacts */}
                    <ListItem button onClick={handleAllContacts} classes={{ gutters: classes.ListItemPadding }}>
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faAddressBook} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="All Contacts" />
                        {allContacts ? <ArrowDropDownIcon /> : < ArrowRightIcon />}
                    </ListItem>
                    <Collapse in={allContacts} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/add-new-contact"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Add New Contact" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/upload-contacts"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Import Contacts" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/manage-contacts"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Manage Contacts" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/intouch-settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Intouch Setting" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/intouch-trigger-settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Intouch Trigger Setting" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/vipecloud-settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Vipecloud Settings" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/coreg-settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Coreg Settings" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/slack/settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Slack Settings" />
                            </ListItem>
                            {(userAccount.usr_role_id === '3' || userAccount.usr_role_id === '2') &&
                                <ListItem
                                    button
                                    className={classes.SublistContainerPadding}
                                    component={NavLink}
                                    to="/contacts/unsubscribe-vipecloud"
                                    onClick={() => { closeSubNavigation() }}
                                >
                                    <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                    <ListItemText classes={{ primary: classes.SublistTitles }} primary="Vipecloud Unsubscribes" />
                                </ListItem>
                            }
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/unsubscribed"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Unsubscribed Contacts" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/download-contacts"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Download contacts" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/update-contacts"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Update Contact" />
                            </ListItem>
                            {(userAccount.usr_role_id === '2' || userAccount.usr_role_id === '4') &&
                                <ListItem
                                    button
                                    className={classes.SublistContainerPadding}
                                    component={NavLink}
                                    to="/contacts/dnc-denied-phones"
                                    onClick={() => { closeSubNavigation() }}
                                >
                                    <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                    <ListItemText classes={{ primary: classes.SublistTitles }} primary="DNC Denied Phones" />
                                </ListItem>
                            }
                        </List>
                    </Collapse>
                    {/* ....................... */}
                    {/* Advance */}
                    <ListItem button onClick={handleAdvance} classes={{ gutters: classes.ListItemPadding }}>
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faShieldAlt} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Advanced" />
                        {advanceContact ? <ArrowDropDownIcon /> : < ArrowRightIcon />}
                    </ListItem>
                    <Collapse in={advanceContact} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/create-keyword"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Create Keyword" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/manage-keywords"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Manage Keywords" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/im-messages"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="IM Messages" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/import-unsubscribe"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Add Suppression File" />
                            </ListItem>
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/all-responses"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="All Responses" />
                            </ListItem>
                            {(userAccount.usr_role_id === '2' || userAccount.usr_role_id === '4') &&
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/shortlink-settings"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Short Link Settings" />
                            </ListItem>
                            }
                             {(userAccount.usr_role_id === '2' || userAccount.usr_role_id === '4') &&
                            <ListItem
                                button
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/contacts/shortlink-analytics"
                                onClick={() => { closeSubNavigation() }}
                            >
                                <ListItemIcon classes={{ root: classes.ListSubmenuIconArrow }}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{ primary: classes.SublistTitles }} primary="Short Link Analytics" />
                            </ListItem>
                            }
                        </List>
                    </Collapse>
                    {/* ..................... */}
                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(ContactSubmenu);
