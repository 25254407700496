import React from 'react';
import AppRoute from './AppRoute';
import Loader from '../helper/loaders';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { auth } from '../utils/authentication/auth';
import { dashboardLayout } from '../layouts/DashboardLayout';
import { errorLayout } from '../layouts/ErrorLayout';
import publicLayout from '../layouts/PublicLayout/PublicLayout';
import { Route, withRouter } from 'react-router';
import WelcomePage from '../components/Welcome';
import FileUpload from '../components/FileUpload';
import AuthRoutes from './NestedRoutes/AuthRoutes';
import ManagerRoutes from './NestedRoutes/ManagerRoutes';
import ContactRoutes from './NestedRoutes/ContactRoutes';
import AdvanceRoutes from './NestedRoutes/AdvanceRoutes';
import ProfileRoutes from './NestedRoutes/ProfileRoutes';
import CampaignsRoutes from './NestedRoutes/CampaignRoutes';
import AdminRoutes  from './NestedRoutes/AdminRoutes';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../containers/not-found-page.component')
);

// const NoAccess = Loader(() =>
//     import(/* webpackChunkName: "NoAccess" */ '../containers/NoAccess/NoAccess')
// );

// const AuthError = Loader(() =>
//     import(/* webpackChunkName: "AuthError" */ '../containers/AuthError/AuthError')
// );


const Routers = (props) => {

    return (
        <Router>
            <Switch>
                <AppRoute
                    exact={true}
                    path='/'
                    component={WelcomePage}
                    layout={publicLayout}
                    type='public'
                    title='Welcome'
                />

                <AppRoute
                    exact={true}
                    path='/file-upload'
                    component={FileUpload}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='File Upload'
                />

                <AppRoute
                    exact={true}
                    path='/dashboard'
                    component={FileUpload}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='dashboard'
                />

                <Route
                    path='/manager'
                    component={ManagerRoutes}
                />

                <Route
                    path='/auth'
                    component={AuthRoutes}
                />

                <Route
                    path='/contacts'
                    component={ContactRoutes}
                />

                <Route
                    path='/advance'
                    component={AdvanceRoutes}
                />

                <Route
                    path='/profile'
                    component={ProfileRoutes}
                />

                <Route
                    path='/campaigns'
                    component={CampaignsRoutes}
                />

                <Route
                    path='/admin'
                    component={AdminRoutes}
                />

                {/* <AppRoute
                        exact={true}
                        path='/NoAccess'
                        component={NoAccess}
                        requireAuth={() => false}
                        layout={dashboardLayout}
                        
                    />  */}

                <AppRoute
                    exact
                    path='*'
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </Router>
    );
};

export default withRouter(Routers);
