// url to fetch api's
export const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL;

export const API_ENDPOINTS = {
    getUserSubscriptionList: API_GATEWAY_URL + "users/get_subscription_list",
    signUp: API_GATEWAY_URL + 'users/register_user_phase_one',
    chargeMyCard: API_GATEWAY_URL + 'users/register_user_phase_two',
    confirmChargeMyCard: API_GATEWAY_URL + 'users/register_user_phase_three',
    setTimeZone: API_GATEWAY_URL + 'users/register_user_phase_four',
    setTerms: API_GATEWAY_URL + 'users/register_user_phase_five',
    signIn: API_GATEWAY_URL + 'users/login',
    sessionToken: API_GATEWAY_URL + 'users/validate_token',
    getNumbersList: API_GATEWAY_URL + 'users/get_twilio_numbers',
    buyNumber: API_GATEWAY_URL + 'users/register_user_phase_six',
    addFolder: API_GATEWAY_URL + 'folder/add',
    getFolderList: API_GATEWAY_URL + 'folder/listAll',
    addCategory: API_GATEWAY_URL + 'category/add',
    updateFolder: API_GATEWAY_URL + 'folder/update',
    getCategoriesList: API_GATEWAY_URL + 'category/listAll',
    updateCategory: API_GATEWAY_URL + 'category/update',
    addGroup: API_GATEWAY_URL + 'group/add',
    getGroups: API_GATEWAY_URL + 'group/listAll',
    addTemplate: API_GATEWAY_URL + 'template/add',
    deleteGroups: API_GATEWAY_URL + 'group/delete',
    mediaListAll: API_GATEWAY_URL + 'media/listAll',
    templateDetails: API_GATEWAY_URL + 'template/details',
    getDropdownGroupsList: API_GATEWAY_URL + 'dropdown/group/list_all',
    addContact: API_GATEWAY_URL + 'contact/add',
    uploadContactFile: API_GATEWAY_URL + 'contact/upload_contact_file',
    addContactFile: API_GATEWAY_URL + 'contact/add_contact_file',
    forgetPassword : API_GATEWAY_URL + "users/forgot_password",
    ResetPassword : API_GATEWAY_URL + "users/reset_password",
    validateForgotLink : API_GATEWAY_URL + "users/validate_forgot_link",
    changePassword : API_GATEWAY_URL + "users/change_password",
    saveUpdateContactUploadedFile : API_GATEWAY_URL + "contact/upload_update_contacts_file",
    UpdateFileContacts: API_GATEWAY_URL + "contact/update_file_contacts",
    VerifyAccount : API_GATEWAY_URL + "profile/verify_two_factor_code_login",
    downloadActiveSubscriber: API_GATEWAY_URL + "contact/download_active_subscribers",
    checkProviderType: API_GATEWAY_URL + "users/register_user_phase_provider",
    getUserBandwidthDetails: API_GATEWAY_URL + "users/get_user_bandwidth_details",
    getBandwidthNumbers: API_GATEWAY_URL + "users/get_bandwidth_numbers",
    buyBandwidthNumbers : API_GATEWAY_URL + "users/buy_bandwidth_number",
    signUpPinkTel : API_GATEWAY_URL + "users/register_pink_user_phase_one",
    getUserSubscriptionListPinkTel : API_GATEWAY_URL + "users/get_pink_subscription_list",
    pinkChargeMyCard: API_GATEWAY_URL + 'users/register_pink_user_phase_two',
    pinkConfirmChargeMyCard: API_GATEWAY_URL + 'users/register_pink_user_phase_three',
    pinkSetTimeZone: API_GATEWAY_URL + 'users/register_pink_user_phase_four',
    pinkSetTerms: API_GATEWAY_URL + 'users/register_pink_user_phase_five',
    pinkBuyNumber : API_GATEWAY_URL + 'users/register_pink_user_phase_six',
    pinkGetNumbersList: API_GATEWAY_URL + 'users/get_pink_twilio_numbers',
    getAdminSubscriptionList : API_GATEWAY_URL + 'users/get_admin_subscription_list', 
    usersLogout : API_GATEWAY_URL + 'users/logout',
    sendPinkTwoFactorCode : API_GATEWAY_URL + 'users/register_pink_user_two_factor_signup',
    verifyPinkTwoFactorCode : API_GATEWAY_URL + 'users/verify_two_factor_code_signup',
    pinkUserWizard: API_GATEWAY_URL + 'users/register_pink_user_wizard',
    getDefaultTollFreeNumbers: API_GATEWAY_URL + 'users/get_default_toll_free_numbers',
    getUserData: API_GATEWAY_URL + 'users/get_users_data',
    updateSubsriberForm: API_GATEWAY_URL + "group/update_html_form",
    getSubscriberFormDetails: API_GATEWAY_URL + "group/html_form_details",
    saveNote: API_GATEWAY_URL + "contact/update_notes",
    getDropdownGroupsListV3: API_GATEWAY_URL + 'dropdown/group/list_all_V3',
};