import { setCookieStorage } from '../utils/CookieLocalStorage';

export const userLogout = (props) => {
    let deleteSessionTokenCookie = setCookieStorage('session_token', null, { path: '/', sameSite: true });
    if (deleteSessionTokenCookie && props.history) {
        return props.history.push('/auth/login');
    }
    else if (!props.success && props.message_code === 10001) {
        // message_code 10001 determined the session token is unauthorized or it expired
        window.location.assign('/auth/login?sessexpire=true');
    }
    
      // usersLogoutAPI().then((res) => {
    //     if(res.success &&res.message_code === 2150){
    //         let deleteSessionTokenCookie = setCookieStorage('session_token', null, { path: '/', sameSite: true });
    //         if (deleteSessionTokenCookie && props.history) {
    //             return props.history.push('/auth/login');
    // }
    //     }
    //     else if (!props.success && props.message_code === 10001) {
    //         // message_code 10001 determined the session token is unauthorized or it expired
    //         window.location.assign('/auth/login?sessexpire=true');
    //     }
    // })
    
}

export const getUnixTimeStamp = () => {
    return Math.floor(new Date().getTime() / 1000);
}

export const UnixTimestampToISOstring = (unixTimestamp) => {
    return new Date(unixTimestamp * 1000).toISOString();
}

export const getHours = () => {
    let Hours = [];
    for (let hour = 1; hour <= 12; ++hour) {
        let hr = String(hour).padStart(2, '0');
        Hours.push({ value: hr, label: hr });
    }
    return Hours;
}

export const get24Hours = () => {
    let Hours = [];
    for (let hour = 0; hour < 24; ++hour) {
        let hr = String(hour).padStart(2, '0');
        Hours.push({ value: hr, label: hr });
    }
    return Hours;
}

export const getMinutes = () => {
    let Minutes = [];
    for (let minute = 0; minute < 60; minute+=5) {
        let min = String(minute).padStart(2, '0');
        Minutes.push({ value: min, label: min });
    }
    return Minutes;
}

export const get5Minutes = () => {
    let Minutes = [];
    for (let minute = 0; minute < 60; minute+=5) {
        let min = String(minute).padStart(2, '0');
        Minutes.push({ value: min, label: min });
    }
    //console.log(Minutes);
    return Minutes;    
}

export const getSortTimeStamp = (dripItem) => {
    let date = new Date(); 
    let year =  date.getUTCFullYear();
    let month = date.getUTCMonth()+1;
    let day  = date.getUTCDay();
    let drp_date = '';
    let drp_timestamp = '';

    if(dripItem.drp_type === "HRS_MIN"){
        drp_date  = new Date(year, month, day, dripItem.drp_hours , dripItem.drp_minutes, '00', '00');
        drp_timestamp = drp_date.getTime();
    }
    else{    
        let convertedHour =  convertedHours(dripItem.drp_hours, dripItem.drp_meridiem);
        drp_date  = new Date(year, month, parseInt(dripItem.drp_days) + day, convertedHour , dripItem.drp_minutes, '00', '00');
        drp_timestamp = drp_date.getTime();
    }
    return drp_timestamp;
}

export const getSortTimeStampFollowup = (dripItem) => {
    let date = new Date(); 
    let year =  date.getUTCFullYear();
    let month = date.getUTCMonth()+1;
    let day  = date.getUTCDay();
    let followup_date = '';
    let followup_timestamp = '';

    if(dripItem.followup_type === "HRS_MIN"){
        followup_date  = new Date(year, month, day, dripItem.followup_hours , dripItem.followup_minutes, '00', '00');
        followup_timestamp = followup_date.getTime();
    }
    else{    
        let convertedHour =  convertedHours(dripItem.followup_hours, dripItem.followup_meridiem);
        followup_date  = new Date(year, month, parseInt(dripItem.followup_days) + day, convertedHour , dripItem.followup_minutes, '00', '00');
        followup_timestamp = followup_date.getTime();
    }
    return followup_timestamp;
}
// This function will convert 12 hrs date format to 24 hrs date format for sorting
const convertedHours = (hours, meridiam) => {
    if (hours < 12 && meridiam === 'PM') {
        hours = hours + 12;
    } else if (hours === 12 && meridiam === 'AM') {
        hours = '00';
    }
    return hours;
}

export const getCurrentIntervalTime = () =>
{
    let dateToReturn = null;
    let minIntervals = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    let date = new Date();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    
    let minInt = parseInt(minutes);
    let newminute = minutes;
    let newhrs = hours;
    let newampm = ampm;
    
    if(!minIntervals.includes(minInt))
    {
        let minDifferance = 0;
        
        for(let i=0; i<minIntervals.length; i++)
        {
            if(minInt > 55)
            {
                minDifferance = (60 - minInt);
                newminute = '00';

                if(hours === 11){
                    newampm = (ampm === 'AM') ? 'PM' : 'AM';
                }
                if(hours === 12){
                    newhrs = '01';
                }
                else{
                    newhrs = hours + 1;
                }
                break;
            }
            else if(minInt > minIntervals[i] && minInt < minIntervals[i+1])
            {
                let deductFrom = minIntervals[i+1];
                minDifferance = (deductFrom - minInt);

                newminute = minInt + minDifferance;
                newminute = (newminute < 10) ? "0"+newminute : newminute;
                break;
            }
        }
        
    }
    
    let fullDate = [month, day, date.getUTCFullYear()].join("/");
    let fullTime = newhrs + ':' + newminute + ' ' + newampm;
    dateToReturn = fullDate+' '+fullTime;

    return dateToReturn;
}

export const validateMimeType = (file, callback) =>
{
    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/gif',
            pattern: [0x47, 0x49, 0x46, 0x38],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/bmp',
            pattern: [0x42, 0x4D],
            mask: [0xFF, 0xFF],
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    var blob = file.slice(0, 4); //read the first 4 bytes of the file

    var reader = new FileReader();
    reader.onloadend = function(e) {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);
            
            for (var i=0, l = mimes.length; i<l; ++i) {
                if (check(bytes, mimes[i])){ 
                    if(mimes[i].mime !== file.type)
                        return callback('error');
                }
            }
        }
    };
    reader.readAsArrayBuffer(blob);
}

function check(bytes, mime) {
    for (var i = 0, l = mime.mask.length; i < l; ++i) {
        if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
            return false;
        }
    }
    return true;
}