import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, withStyles, AppBar, Toolbar, IconButton, Hidden, Drawer,  Popover,  Divider, Button
    } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Span from "@material-ui/core/Box";
import TelegramIcon from '@material-ui/icons/Telegram';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from "../../assets/images/logo.png"
import SideNavigationsMenu from '../../components/SideNavigations/SideNavigationsMenu';
import { userLogout } from '../../helper/commonFunction';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserAccountContext } from '../../contextHooks';
import Circularloader from '../../helper/loaders/CircularLoader';
//import { setLocalStorage } from '../../utils/CookieLocalStorage';

const styles = (theme) => ({
    Logo: { width: 97 },
    AppbarCss: { boxShadow: 'none', background: '#fff', height: 62, borderTop: '3px solid #fb6e8a', borderBottom: '1px solid #dadada', top:0 },
    ToolBarHeight: { minHeight: 52 },
    HeaderItems: { color: '#3d3d3d', fontWeight: 500 },
    ItemContainer: { padding: 0, borderRadius: 0, background: 'transparent', margin: '0px 5px', color: '#2b2b2b', '&:hover': { background: 'transparent' } },
    HeaderIcons: { fontSize: 33 },
    HeaderItemText: { fontSize: 11, lineHeight: '0.60rem', color: '#000' },
    BadgeCss: { transform: 'initial', minWidth: 10, height: 10, marginRight: 4, marginTop: 10, background: '#fb6e8a', padding: '0px 0px' },
    MenuButton: { padding: 0 },
    drawerPaper: { marginTop: 62, background: '#fb6e8a', boxShadow: 'none', width: 70, borderRight: 'none', },
    HeaderIconIM:{fontSize:26, marginTop:4, marginBottom:2},

    emailLink:{color:'#3d3d3d',fontWeight:500,margin:'0px 10px'},
    ProfileDropdown:{marginTop:18, width:250, outline:'none', padding:'12px 6px'},
    UserCircleIcon:{fontSize:65,},
    UserDetails:{fontSize:15, marginTop:3, wordBreak:'break-all'},
    UserBtns:{fontSize:12, border:'1px solid #fb6e8a', color:'#fb6e8a', margin:'0px 4px', padding:'5px 7px', '&:hover':{background:'none'}},
    UserBtnIcons:{fontSize:21, marginRight:2},
    LoaderCls:{position:"relative", height:25},
    manageUsrLink:{color:'#3d3d3d', textDecoration:"none"},
    manageUsrLinkEdit:{color:'#3d3d3d', textDecoration:"underline"},
    linkcss:{color:'#fb6e89', textDecoration:"none"},
    smallProfilePic:{height:32, width:32, border:'2px solid #fa6e89', borderRadius:'50%'},
    bigProfilePic:{height:65, width:65, border:'2px solid #fa6e89', borderRadius:'50%'}
});

const Navbar = (props) => {
    const { classes, fundsLoader, handleFundsDetails, fundsDetails } = props;
    
    const userAccount = useContext(UserAccountContext);
    let account_balance = (fundsDetails) ? parseFloat(fundsDetails.usr_account_balance) : parseFloat(userAccount.usr_account_balance);
    let monthly_balance = (fundsDetails) ? parseFloat(fundsDetails.usr_monthly_balance) : parseFloat(userAccount.usr_monthly_balance);
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const open = Boolean(anchorEl);
    const phoneNumRegexE10 = /\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g;

    const handleMenu = (event) => { 
        setAnchorEl(event.currentTarget);
        handleFundsDetails(); 
    };

    const handleClose = () => { setAnchorEl(null); };

    const handleExpandClick = () => { setExpanded(!expanded); };

    const onClickLogoutHandler = () => {
        localStorage.removeItem("campaign_followups");
        localStorage.removeItem("keyword_followup_details");
        setAnchorEl(null);
        userLogout(props);
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" classes={{ root: classes.AppbarCss }}>
                <Toolbar className={classes.ToolBarHeight}>
                    <Hidden only={['sm', 'xl', 'md', 'lg']}>
                        <IconButton className={classes.MenuButton} disableRipple onClick={handleExpandClick} >
                            <Span align="center" display="grid">
                                {(expanded) ? <CloseIcon classes={{ root: classes.HeaderIcons }} /> : <MenuIcon classes={{ root: classes.HeaderIcons }} />}
                            </Span>
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={expanded}
                            onClose={handleExpandClick}
                            classes={{ paperAnchorLeft: classes.drawerPaper }}
                            ModalProps={{
                                BackdropProps: { invisible: true }
                            }}
                        >
                            <SideNavigationsMenu 
                                pageUri = {props.location.pathname}
                            />
                        </Drawer>
                    </Hidden>
                    <Span flexGrow={1} mt={1}>
                        <a href="/campaigns/create-campaign">
                            <img className={classes.Logo} src={Logo} alt='header-logo' />
                        </a>
                    </Span>
                    {
                        (userAccount.usr_role_id === '2' || userAccount.usr_role_id === '3') &&
                        <Hidden only="xs">
                        <Span display="flex">
                            <Typography variant="body2" className={classes.emailLink}>
                                {
                                (userAccount.usr_role_id === '2') ? 
                                <Link to={`/admin/edit-user/${userAccount.usr_id}`} className={classes.manageUsrLinkEdit}>
                                    {userAccount.usr_email}
                                </Link> :
                                    <span>{userAccount.usr_email}</span>
                                }
                            </Typography>
                            |
                            <Typography variant="body2" className={classes.emailLink}>
                            {  
                                (userAccount.usr_role_id === '3') ?
                                <Link to="/manager/manage-users" className={classes.manageUsrLink}>
                                    Manage Users
                                </Link> : 
                                <Link to="/admin/manage-users" className={classes.manageUsrLink}>
                                    Manage Users
                                </Link>
                            }
                            </Typography>
                        </Span>
                        </Hidden>
                    }
                    <IconButton 
                        className={classes.ItemContainer} disableRipple
                        component={NavLink} to="/contacts/im-messages"
                    >
                        <Span align="center" display="grid" justifyItems="center">
                            <ChatBubbleIcon classes={{root:classes.HeaderIconIM}} />
                            <Typography variant="caption" className={classes.HeaderItemText}>Messages</Typography>
                        </Span>
                    </IconButton>
                    <IconButton 
                        className={classes.ItemContainer} disableRipple
                        component={NavLink} to="/campaigns/create-campaign"
                    >
                        <Span align="center" display="grid" justifyItems="center">
                            <TelegramIcon classes={{ root: classes.HeaderIcons }} />
                            <Typography variant="caption" className={classes.HeaderItemText}>Campaigns</Typography>
                        </Span>
                    </IconButton>
                    {/* <IconButton className={classes.ItemContainer} disableRipple>
                        <Span align="center" display="grid" justifyItems="center">
                            <Badge
                                badgeContent=''
                                color="secondary"
                                classes={{ anchorOriginTopRightRectangle: classes.BadgeCss }}
                            >
                                <NotificationsIcon classes={{ root: classes.HeaderIcons }} />
                            </Badge>
                            <Typography variant="caption" className={classes.HeaderItemText}>Alert</Typography>
                        </Span>
                    </IconButton> */}
                    {/* <IconButton className={classes.ItemContainer} disableRipple>
                        <Span align="center" display="grid" justifyItems="center">
                            <SettingsIcon classes={{ root: classes.HeaderIcons }} />
                            <Typography variant="caption" className={classes.HeaderItemText}>Settings</Typography>
                        </Span>
                    </IconButton> */}
                    <div>
                        <IconButton className={classes.ItemContainer} disableRipple aria-haspopup="true"  onClick={handleMenu}>
                            {
                                (userAccount.usr_image_url) ?
                                <img src={userAccount.usr_image_url} alt="ProfilePic" className={classes.smallProfilePic}/> :
                                <AccountCircle classes={{root:classes.HeaderIcons}} /> 
                            }
                        </IconButton>
                        <Popover
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                            open={open}
                            onClose={handleClose}
                            classes={{paper:classes.ProfileDropdown}}
                        >
                            <Span align='center'>
                                {
                                (userAccount.usr_image_url) ?
                                    <img src={userAccount.usr_image_url} alt="ProfilePic" className={classes.bigProfilePic}/> :
                                    <FontAwesomeIcon icon={faUserCircle} className={classes.UserCircleIcon}/>
                                }
                                <Typography variant='subtitle2' className={classes.UserDetails}>
                                    {userAccount.usr_first_name} {userAccount.usr_last_name}
                                </Typography>
                                <Typography variant='subtitle2' className={classes.UserDetails}>
                                    Primary Number: {
                                        (userAccount.usr_primary_number) ?
                                        userAccount.usr_primary_number.replace(phoneNumRegexE10, "($1) $2-$3") :
                                        "NA"
                                    }
                                </Typography>
                                <Typography variant='subtitle2' className={classes.UserDetails}>
                                    Email: {userAccount.usr_email}
                                </Typography>
                                {
                                    (userAccount.next_bill_date) &&
                                    <Typography variant='subtitle2' className={classes.UserDetails}>
                                        Next Billing: {userAccount.next_bill_date}
                                    </Typography>
                                }
                                <Span mt={1} mb={1}><Divider/></Span>
                                {
                                    (fundsLoader) ?
                                    <Span my={1 } className={classes.LoaderCls}>
                                        <Circularloader />
                                    </Span>
                                    :
                                    <span>
                                    <Typography variant='subtitle2' className={classes.UserDetails}>
                                        Anytime Credits : ${account_balance.toFixed(2)} ({Math.round(account_balance/0.01)})
                                    </Typography>
                                    <Typography variant='subtitle2' className={classes.UserDetails}>
                                        Monthly Credits : ${monthly_balance.toFixed(2)} ({Math.round(monthly_balance/0.01)})
                                    </Typography>
                                    </span>
                                }
                                <Span mt={1}><Divider/></Span>
                                <Span mt={2}>
                                    <Button size='small' className={classes.UserBtns}>
                                        <HeadsetMicIcon className={classes.UserBtnIcons}/> 
                                        {userAccount.usr_role_id === "4" ? 
                                            <a href='mailto:support@betwext.com' className={classes.linkcss}> Support </a>   
                                            :
                                            <a href='https://www.pinksuccess.net/pinktel-training' className={classes.linkcss}> Support </a> 
                                        } 
                                    </Button>
                                    <Button 
                                        size='small' 
                                        className={classes.UserBtns}
                                        onClick={onClickLogoutHandler}
                                    >
                                        <ExitToAppIcon className={classes.UserBtnIcons}/>    
                                        Logout
                                    </Button>
                                </Span>
                            </Span>
                        </Popover>
                    </div>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default withStyles(styles)(withRouter(Navbar));