import React,{useState} from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Collapse, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faUsers, faFillDrip, faUserCog,faFlag} from '@fortawesome/free-solid-svg-icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
    drawerPaper:{marginTop:62, background:'#f5f1f1', width:262, boxShadow:'none', borderRight:'none', marginLeft:70},
    ListItemPadding:{paddingLeft:17, paddingRight:13},
    ListAvatar:{background:'#fb6e8a', color:'#fff', fontSize:14, width:30, height:30},
    ListIconContainer:{minWidth:36},
    ListTitle:{fontSize:14},
    ListSubmenuIconArrow:{minWidth:19, marginLeft:37},
    SublistTitles:{fontSize:13},
    SublistContainerPadding:{paddingTop:4, paddingBottom:4},
    DrawerModel:{position:'relative !important', zIndex:'1200 !important'},
    
    [theme.breakpoints.only('xs')]: {
        DrawerModel:{position:'relative !important', zIndex:'1300 !important'},
    },
})

const TemplateSubmenu = (props) => {
    const {classes, openState, closeSubNavigation} = props;

    const [normalTemplate, setNormalTemplate] = useState(false);
    const handleNormalTemplate = () =>{setNormalTemplate(!normalTemplate)}

    const [dripTemplate, setDripTemplate] = useState(false);
    const handleDripTemplate = () =>{setDripTemplate(!dripTemplate)}

    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{paperAnchorLeft:classes.drawerPaper, modal:classes.DrawerModel}}
                BackdropProps={{style : {top:'62px', left:70}}}
            >
                <List component="nav" disablePadding>
                    {/* Manage Normal Templates */}
                    <ListItem button onClick={handleNormalTemplate} classes={{gutters:classes.ListItemPadding}}>
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faUsers} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Templates" />
                        {normalTemplate ? <ArrowDropDownIcon /> : < ArrowRightIcon/>}
                    </ListItem>
                    <Collapse in={normalTemplate} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-normal-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Templates" />
                            </ListItem>
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-normal-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Template" />
                            </ListItem>
                            {/* <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-normal-category"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Category" />
                            </ListItem> */}
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-normal-category"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Categories" />
                            </ListItem>
                            {/* <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-normal-folder"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Folder" />
                            </ListItem> */}
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-normal-folder"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Folder" />
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* ....................... */}
                    {/* Manage Drip Templates */}
                    <ListItem button onClick={handleDripTemplate} classes={{gutters:classes.ListItemPadding}}>
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFillDrip} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Drip Templates" />
                        {dripTemplate ? <ArrowDropDownIcon /> : < ArrowRightIcon/>}
                    </ListItem>
                    <Collapse in={dripTemplate} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-drip-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Drip Templates" />
                            </ListItem>
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-drip-template"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Drip Template" />
                            </ListItem>
                            {/* <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-drip-category"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Drip Category" />
                            </ListItem> */}
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-drip-category"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Drip Categories" />
                            </ListItem>
                            {/* <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/add-drip-folder"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Add Drip Folder" />
                            </ListItem> */}
                            <ListItem 
                                button 
                                className={classes.SublistContainerPadding}
                                component={NavLink}
                                to="/manager/manage-drip-folder"
                                onClick={()=>{closeSubNavigation()}}
                            >
                                <ListItemIcon classes={{root:classes.ListSubmenuIconArrow}}><FontAwesomeIcon icon={faLongArrowAltRight} /></ListItemIcon>
                                <ListItemText classes={{primary:classes.SublistTitles}} primary="Manage Drip Folders" />
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* ..................... */}
                    {/* <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/manager/manage-birthdays"
                        onClick={()=>{closeSubNavigation()}}
                        //classes={{gutters:classes.ListItemPadding}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faBirthdayCake} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Birthdays" />
                    </ListItem> */}
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/manager/manage-users"
                        onClick={()=>{closeSubNavigation()}}
                        //classes={{gutters:classes.ListItemPadding}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faUserCog} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Manage Users" />
                    </ListItem>
                    <ListItem 
                        button 
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/manager/overdue-users"
                        onClick={()=>{closeSubNavigation()}}
                    >
                        <ListItemIcon classes={{root:classes.ListIconContainer}}>
                            <Avatar classes={{root:classes.ListAvatar}}>
                                <FontAwesomeIcon icon={faFlag} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{primary:classes.ListTitle}} primary="Overdue Users" />
                    </ListItem>

                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(TemplateSubmenu);