import React from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Route from './routes/app.routes';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from "history";

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fb6e8a' }, // Purple and green play nicely together.
    secondary: { main: '#ff4569' } // This is just pink.A700 as hex.
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor:'#fb6e8a',
      },
    },
    MuiPickersToolbarText:{
      toolbarTxt:{
        color:'#fff'
      },
      toolbarBtnSelected:{
        color:'#fff'
      }
    },
    MuiPickersDay:{
      daySelected:{
        color:'#fff'
      }
    },
	MuiPickersClockNumber:{
		clockNumberSelected:{
			color:'#fff'
		}
	}
  },
});

function App(props) {
  const history = createBrowserHistory();

  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter history={history}>
        <Route />
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
