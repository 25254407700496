import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const CreatePublicGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AddPublicGroup')
);

const CreateSyncGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AddSyncGroup')
);

const ManagePublicGroups = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ManagePublicGroup')
);

const ManageSyncGroups = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ManageSyncGroups')
);

const EditPublicGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/EditPublicGroup')
);

const EditSyncGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/EditSyncGroup')
);

const AddPublicContact = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AddPublicContact')
);

const IntouchSettings = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/IntouchSettings')
);

const ManageContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ManageContacts')
);

const ContactInfo = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ContactInfo')
);

const EditContact = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/EditContact')
);

const UploadContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/UploadContacts')
);

const ImportUploadCSVContact = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ImportUploadContact')
);

const CreateKeyword = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Keywords/CreateKeyword')
);

const ManageKeywords = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Keywords/ManageKeywords')
);

const KeywordInfo = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Keywords/KeywordInfo')
);

const EditKeyword = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Keywords/EditKeyword')
);

const Unsubscribed = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/Unsubscribed')
);

const AllResponses = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AllResponses')
);

const IMMessages = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/IMMessages')
);

const ListInfo = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ListInfo')
);

const SupressionContact = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AddSupressionFile')
);

const UploadUpdateContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/UploadUpdateContacts')
);

const CompleteUpdateContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/CompleteUpdateContacts')
);

const DownloadContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */  '../../containers/Contacts/DownloadContacts')
);

const VipecloudSetting = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/VipecloudSetting')
);

const IntouchTriggerSettings = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/IntouchTriggerSettings')
);

const CoregSettings = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/CoregSettings')
);

const SubscriberForm = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/SubscriberForm')
);

const EditSubscriberForm = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/EditSubscriberForm')
);

const SlackSettings = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/SlackSettings')
);

const AddSlackTrigger = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/AddSlackTrigger')
);

const ManageSlackTrigger = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ManageSlackTriggers')
);

const EditSlackTrigger = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/EditSlackTrigger')
);

const UnsubVipeCloud = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/UnsubscribeVipeCloud')
);

const DncDeniedPhones = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/DncDeniedPhones')
);

const ShortLinkSetting = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ShortLinkSetting')
);

const ShortLinkAnalytics = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Contacts/ShortLinkAnalytics')
);

function ManagerRoutes(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact={true}
                    path={`${match.path}/create-public-group`}
                    component={CreatePublicGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Create Public Group'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-public-groups`}
                    component={ManagePublicGroups}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Manage Public Groups'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/create-sync-group`}
                    component={CreateSyncGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Create Sync Group'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-sync-groups`}
                    component={ManageSyncGroups}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Manage Sync Groups'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-public-group/:id`}
                    component={EditPublicGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Edit Public Group'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-sync-group/:id`}
                    component={EditSyncGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Edit Sync Group'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-new-contact`}
                    component={AddPublicContact}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Add New Contact'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-contacts`}
                    component={ManageContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Manage Contacts'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/intouch-settings`}
                    component={IntouchSettings}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Intouch Settings'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/contact-info/:id`}
                    component={ContactInfo}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Contact Info'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-contact/:id`}
                    component={EditContact}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Edit Contact'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/upload-contacts`}
                    component={UploadContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Update Contact'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/import-contacts`}
                    component={ImportUploadCSVContact}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Update Contact'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/update-contacts`}
                    component={UploadUpdateContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Update Contacts'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/complete-update-contacts`}
                    component={CompleteUpdateContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Complete Update Contacts'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/create-keyword`}
                    component={CreateKeyword}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Create New Keyword'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-keywords`}
                    component={ManageKeywords}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Manage Keywords'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/keyword-info/:id`}
                    component={KeywordInfo}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Keyword Info'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-keyword/:id`}
                    component={EditKeyword}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Keyword Info'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/unsubscribed`}
                    component={Unsubscribed}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Unsubscribed'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/all-responses`}
                    component={AllResponses}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | All Responses'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/im-messages`}
                    component={IMMessages}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | IM Messages'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/group-details/:id`}
                    component={ListInfo}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Group Details'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/import-unsubscribe`}
                    component={SupressionContact}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Add Supression File'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/download-contacts`}
                    component={DownloadContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Download Contacts'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/vipecloud-settings`}
                    component={VipecloudSetting}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Vipecloud Setting'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/intouch-trigger-settings`}
                    component={IntouchTriggerSettings}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Intouch Trigger Settings'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/coreg-settings`}
                    component={CoregSettings}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Coreg Setting'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/subscribe-form/:id`}
                    component={SubscriberForm}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Subscribe Form'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-subscribe-form/:id`}
                    component={EditSubscriberForm}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts |Update Subscribe Form'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/slack/settings`}
                    component={SlackSettings}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Slack Settings'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/slack/add-trigger`}
                    component={AddSlackTrigger}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Add Trigger'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/slack/manage-triggers`}
                    component={ManageSlackTrigger}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Manage Triggers'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/slack/edit-trigger/:id`}
                    component={EditSlackTrigger}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Update Triggers'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/unsubscribe-vipecloud`}
                    component={UnsubVipeCloud}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Unsubscribe Vipecloud'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/dnc-denied-phones`}
                    component={DncDeniedPhones}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | DNC Denied Phones'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/shortlink-settings`}
                    component={ShortLinkSetting}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | Short Link Setting'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/shortlink-analytics`}
                    component={ShortLinkAnalytics}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Contacts | ShortLink Analytics'
                />
            
                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>
    )
}

export default ManagerRoutes;
