import { getSessionToken } from '../../../utils/authentication/jwtUtils';
import { serviceRequest } from '../../apihelper';
import { API_ENDPOINTS_URLS } from '../apiendpointurls';

export const GetRechargeSettingsAPI = () => {
    let url = API_ENDPOINTS_URLS.getRechargeSettings;

    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const GetOrderHistoryAPI = () => {
    let url = API_ENDPOINTS_URLS.getOrderHistory;
    
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const GetCardsOnFileAPI = () => {
    let url = API_ENDPOINTS_URLS.getCardsOnFile;
    
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const UpdateRechargeSettingsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateRechargeSettings;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const ChargeCardAPI = (params) => {
    let url = API_ENDPOINTS_URLS.chargeCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const addNewCreditCardAPI = (params) => {
    let url = API_ENDPOINTS_URLS.addNewCreditCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const GetVoicemailSettingsAPI = () => {
    let url = API_ENDPOINTS_URLS.getVoicemailSettings;

    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};


export const SendCodeAPI = (params) => {
    let url = API_ENDPOINTS_URLS.sendCode;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const VerifyCodeAPI = (params) => {
    let url = API_ENDPOINTS_URLS.callforwardVerifyCode;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};


export const AddVoicemailSettingsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.addVoicemailSettings;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const UpdateVoicemailSettingsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateVoicemailSettings;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetSubscriptionAPI = () =>{
    let url = API_ENDPOINTS_URLS.getSubscriptionDetail;
    
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);   
}

export const GetProfileDetailsAPI = () =>{
    let url = API_ENDPOINTS_URLS.getProfileDetail;
    
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);   
}

export const UpdateGenericResponseAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateGenericResponse;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const UpdateTimezoneAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateTimezone;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetVoicemailsAPI = (params) =>{
    let url = API_ENDPOINTS_URLS.getVoicemails;
    
    Object.entries(params).forEach(([key, val])=>{
        if(key === 'voicemail_type')
            url+=`?${key}=${val}`;
        else 
            url+=`&${key}=${val}`;
    });

    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);   
}

export const GetForwardedCallsAPI = (params) =>{
    let url = API_ENDPOINTS_URLS.getForwardedCalls;
    
    Object.entries(params).forEach(([key, val])=>{
        if(key === 'num')
            url+=`?${key}=${val}`;
        else 
            url+=`&${key}=${val}`;
    });

    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);   
}

export const DeleteVoicemailAPI = (params) => {
    let url = API_ENDPOINTS_URLS.deleteVoicemail;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetAllInvoicesAPI = (params) =>{
    let url = API_ENDPOINTS_URLS.getAllInvoices;

    Object.entries(params).forEach(([key, val])=>{
        if(key === 'auth_trans_type')
            url+=`?${key}=${val}`;
        else 
            url+=`&${key}=${val}`;
    });

    let requestOptions = {
        method : 'GET',
        headers: {
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
}

export const UpdateDefaultCardAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateDefaultCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetInvoiceDetailsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.getInvoiceDetails;
    url+= `?invoice_num=${params['invoice_num']}` ;

    let requestOptions = {
        method : "GET",
        headers: {
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
}

export const GetProfileBannerAPI =() =>{
    let url = API_ENDPOINTS_URLS.getProfileBanner;

    let requestOptions ={
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const GetFundsAPI =() =>{
    let url = API_ENDPOINTS_URLS.getfunds;

    let requestOptions ={
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}
export const ManageNumbersAPI = (params) => {
    let url = API_ENDPOINTS_URLS.manageNumbers;

    Object.entries(params).forEach(([key, val])=> {
        if(key === 'num')
            url+=`?${key}=${val}`;
        else 
            url+=`&${key}=${val}`;
    });

    let requestOptions = {
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const UpdatePrimaryNumberAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updatePrimaryNumber;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const GetNumberByAreaCodeAPI = (params) => {
    let url = API_ENDPOINTS_URLS.getNumberByAreaCode;

    Object.entries(params).forEach(([key, val])=> {
        if(key === 'area_code')
            url+=`?${key}=${val}`;
        else 
            url+=`&${key}=${val}`;
    });

    let requestOptions = {
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const SendSwapRequestAPI = (params) => {
    let url = API_ENDPOINTS_URLS.sendSwapRequest;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const ProcessOverdueInvoiceAPI = (params) => {
    let url = API_ENDPOINTS_URLS.processOverdue;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const UpdateViolationStateAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateViolation;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const RemoveCreditCardAPI = (params) => {
    let url = API_ENDPOINTS_URLS.removeCrad;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const BuyAccountNumberAPI = (params) => {
    let url = API_ENDPOINTS_URLS.buyNumbers;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}
export const SendTwoFacrorCodeAPI = (params) => {
    let url = API_ENDPOINTS_URLS.sendTwoFactorCode;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const GetTwoFactorSettingAPI = (params) => {
    let url = API_ENDPOINTS_URLS.getTwoFactorSetting;

    let requestOptions = {
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const UpdateTwoFactorSettingAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateTwoFactorSetting;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const VerifySendCodeAPI = (params) => {
    let url = API_ENDPOINTS_URLS.verifyCode;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)
}

export const getQRCodeAPI = () => {
    let url = API_ENDPOINTS_URLS.getQRCode;

    let requestOptions = {
        method:'GET',
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    return serviceRequest(url, requestOptions)
}

export const UpdateProfileImageAPI = (params) => {
    let url = API_ENDPOINTS_URLS.updateProfileImage;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};