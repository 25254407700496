import React, { useEffect } from 'react';
//import { getSessionToken } from '../utils/authentication/jwtUtils';

const Welcome = (props) =>{
    const { history } = props;
    useEffect(() => {
        history.push('/auth/login');
        // if(getSessionToken()){
        //     history.push('/auth/charge-my-card');
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <h2 style={{textAlign:'center', color:'#ff3e5f'}}>Welcome, PinkTel</h2>
    );
}

export default Welcome;