import React, { useState, useEffect } from 'react';
import AuthHeader from '../../components/Auth/Header';
// import Footer from '../../components/Footer/Footer';
import { UserAccountContext } from '../../contextHooks';
import { validateSessionTokenAPI } from '../../helper/services/API/Users';
import { userLogout } from '../../helper/commonFunction';
import Snackbar from '../../components/Snackbar/Snackbar';
import Loader from '../../helper/loaders/ComponentLoader';
import { Auth_Phase_Redirection } from '../../helper/constants';

const PinkAuthLayout = (props) => {
    const [userAccount, setUserAccount] = useState({});
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });

    useEffect(()=>{
        validateSessionTokenAPI().then((res)=>{
            if(res.success && res.data){
                if(res.data.data.usr_role_id !== '4'){
                    let phase = res.data.data.meta_phase;
                    let redirect = Auth_Phase_Redirection[phase];
                    window.location.href = redirect;
                }
                setUserAccount(res.data.data);
            }
            else {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: 'Session Expired! Logout Successfully',
                        variant: 'success'
                    }
                });
                userLogout(props.children.props);
            }
        }).catch((error)=>{
            console.log(error);
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went wrong!',
                    variant: 'error'
                }
            });
        });

    },[props.children.props]);


    return (
        <React.Fragment>
            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({ messageInfo: { open: false, message: null, variant: 'success' } });
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={5000}
            />}
            {
                Object.keys(userAccount).length > 0  ?
                    <React.Fragment>
                        <UserAccountContext.Provider value={userAccount}>
                            <AuthHeader 
                                prps = {props.children.props}
                            />
                            {props.children}
                        </UserAccountContext.Provider>
                        {/* <Footer /> */}
                    </React.Fragment>
                    : 
                    <Loader />
                }
        </React.Fragment>
    );
}

export default PinkAuthLayout;