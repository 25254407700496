import AWS from 'aws-sdk';

AWS.config.region = process.env.REACT_APP_AWS_REGION; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
});

const awsStorageBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_S3BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
});


const UploadFile = (file, fileName) =>{
    return new Promise((resolve, reject)=>{
        let params = {
            level: process.env.REACT_APP_AWS_S3BUCKET_LEVEL,
            Key: `usersMedia/${fileName}`,
            ContentType: file.type,
            Body: file,
        }
        awsStorageBucket.upload(params, (err, res)=>{
            if(err){
                return reject({
                    status: 'error',
                    errors: err
                });
            }
            return resolve({
                status: 'success',
                data: res
            });
        });
    });
}

const GetUploadFile = (fileNameKey) => {
    return new Promise((resolve, reject)=>{
        let params = { 
            Bucket: process.env.REACT_APP_AWS_S3BUCKET_NAME, 
            Key: fileNameKey
        };
        awsStorageBucket.getObject(params, (err, res)=>{
            if(err){
                return reject({
                    status: 'error',
                    errors: err
                });
            }
            return resolve({
                status: 'success',
                data: res
            });
        });
    });
}

export { UploadFile, GetUploadFile };