import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const ManageUsers = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ './../../containers/Admin/ManageUsers')
);

const EditUsers = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ './../../containers/Admin/EditUsers')
);

const ManageBanner = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ './../../containers/Admin/ManageBanner')
);

const SwapRequest = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/SwapRequests')
);

const ProcessSwap = Loader(() => 
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/UserSwapRequest')
);

const UserDashboard = Loader(() => 
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/UserDashboard')
);

const ActiveUsers = Loader(() => 
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/ActiveUsers')
);

const PendingUsers = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/PendingUsers')
);

const MonthlySpendings = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/MonthlySpendings')
);

const DuplicateInvoices = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/DuplicateInvoices')
);

const SearchGlobalContacts = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/SearchContacts')
);

const ManageA2PStatuses = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/ManageA2PUsers')
);

const AddForeignContact = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Admin/AddForeignContact')
);

function AdminRoute(props){
    let { match } = props;
    return(
        <React.Fragment>
            <Switch>
                <AppRoute 
                    exact={true}
                    path={`${match.path}/manage-users`}
                    component={ManageUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Manage Users'
                />
                 <AppRoute 
                    exact={true}
                    path={`${match.path}/edit-user/:id`}
                    component={EditUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Edit Users'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/manage-banner`}
                    component={ManageBanner}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Manage Users'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/swap-requests`}
                    component={SwapRequest}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Swap Requests'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/process-swap/:id`}
                    component={ProcessSwap}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Process Swap Requests'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/user-dashboard/:id`}
                    component={UserDashboard}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | User Dashboard'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/active-users`}
                    component={ActiveUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Active Users'
                />
                
                <AppRoute 
                    exact={true}
                    path={`${match.path}/manage-pendings`}
                    component={PendingUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Pending Subscriptions'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/monthly-spendings`}
                    component={MonthlySpendings}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Monthly Spendings'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/duplicate-invoices`}
                    component={DuplicateInvoices}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Duplicate Subscriptions'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/search-contacts`}
                    component={SearchGlobalContacts}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Search Contacts'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/manage-a2p`}
                    component={ManageA2PStatuses}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Manage A2P'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/add-foreign-contact/:id`}
                    component={AddForeignContact}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Admin | Add Foreign Contact'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>    
    );
}

export default AdminRoute;