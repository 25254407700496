import React, { useEffect, useState } from 'react';
import { withStyles, Typography} from '@material-ui/core';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import Sidebar from './SideBar';
import Span from '@material-ui/core/Box';
import { UserAccountContext } from '../../contextHooks';
import { validateSessionTokenAPI } from '../../helper/services/API/Users';
import Snackbar from '../../components/Snackbar/Snackbar';
import Loader from '../../helper/loaders/ComponentLoader';
import { UserRoleAuthRoute } from '../../helper/constants';
import { GetProfileBannerAPI, GetFundsAPI } from '../../helper/services/API/Profile';

const styles = (theme) => ({
    BannerContainer:{background:'#333', padding:'6px 10px', borderLeft:'4px solid #ffa500', borderRadius:4, marginLeft:29, marginRight:29, marginBottom:12},
    BannerText:{color:'#fff', fontSize:13, fontWeight:500},
    overdueUsersLink:{color:'#FFFFFF',textDecoration:"none"},
    mainContent: {
        marginRight: 32,
        marginLeft: 100,
        marginTop: 80
    },
    [theme.breakpoints.only('xs')]: {
        mainContent: {
            marginRight: 12,
            marginLeft: 12,
            marginTop: 80
        }
    },
});


const DashboardLayout = (Props) => {
    const { classes, children } = Props;
    const childrenProps = children.props;
    const [userAccount, setUserAccount] = useState({});
    const [bannerSettings, setBannerSettings] = useState(null);
    const [overdueUsers,setOverdueUsers]=   useState(null);
    const [fundsLoader, setFundsLoader] = useState(false);
    const [fundsDetails, setFundsDetails] = useState(null);
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });


    const setUserDataContext = (res) =>{
        if(res.success && res.data){
            setUserAccount(res.data.data);
        }
        else {
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went wrong!',
                    variant: 'error'
                }
            });
        }
    }
    
    const setOverdueState = (res) => {
        if(res.success && res.data){
            setOverdueUsers(res.data);
        }
    }

    const routeAuthentication = (res) => {
        let { location, history } = Props.children.props;

        if (res.data && res.data.data) {
            let { usr_role_id } = res.data.data;

            if (location.pathname.split('/').includes('manager')) {
                if (!UserRoleAuthRoute.manager.includes(parseInt(usr_role_id))) {
                    setSnackbarState({
                        messageInfo: { open: true, message: 'You do not have access to manager page', variant: 'error' }
                    });
                    setTimeout(() => {
                        history.push('/campaigns/create-campaign')
                    }, 1000);
                }
            }
            else if (location.pathname.split('/').includes('admin')) {
                if (!UserRoleAuthRoute.admin.includes(parseInt(usr_role_id))) {
                    setSnackbarState({
                        messageInfo: { open: true, message: 'You do not have access to admin page', variant: 'error' }
                    });
                    setTimeout(() => {
                        history.push('/campaigns/create-campaign')
                    }, 1000);
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            let tokenInfo = await validateToken();
            setUserDataContext(tokenInfo);
            routeAuthentication(tokenInfo);
            setOverdueState(tokenInfo);

            if(tokenInfo.success === true ){
                let bannerInfo = await getBannerDetails();
                if (bannerInfo.success && bannerInfo.message_code === 2145) {
                    setBannerSettings(bannerInfo.data.banner_details);
                }
                else{
                    setSnackbarState({
                        messageInfo: {
                            open: true,
                            message: bannerInfo.message,
                            variant: 'error'
                        }
                    });
                }
            }
        })().catch(err => {
            console.error('Caught error :',err);
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went Wrong!',
                    variant: 'error'
                }
            });
        });

        // validateSessionTokenAPI().then((res) => {
        //     setUserDataContext(res);
        //     routeAuthentication(res);
        // }).catch((error) => {
        //     setSnackbarState({
        //         messageInfo: {
        //             open: true,
        //             message: 'Something went wrong!',
        //             variant: 'error'
        //         }
        //     });
        // });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenProps]);


    const validateToken = async() => {
        try {
            let tokenState = await validateSessionTokenAPI();
            return tokenState;
        } catch (error) {
            throw error;
        };
    }

    const getBannerDetails = async() => {
        try {
            let tokenState = await GetProfileBannerAPI();
            return tokenState;
        } catch (error) {
            throw error;
        };
    }

    const handleFundsDetails = () =>{
        setFundsLoader(true);
        GetFundsAPI().then(res=>{
            setFundsLoader(false);
            if (res && res.success && res.message_code === 2146) {
                setFundsDetails(res.data.fund_details);
            }
            else if(res && !res.success){
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: 'error'
                    }
                });
            }
            else{
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: 'Something went Wrong! Please try again later.',
                        variant: 'error'
                    }
                });
            }
        }).catch(err=>{
            //setBtnLoader(false);
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went Wrong! Please try again later.',
                    variant: 'error'
                }
            });
        });
    }

    return (
        <React.Fragment>
            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({ messageInfo: { open: false, message: null, variant: 'success' } });
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={5000}
            />}
            {
                Object.keys(userAccount).length > 0 ?
                    <React.Fragment>
                        <UserAccountContext.Provider value={userAccount}>
                            <Navbar 
                                fundsLoader={fundsLoader}
                                handleFundsDetails={handleFundsDetails}
                                fundsDetails={fundsDetails}
                            />
                            <Sidebar />
                            <Span className={classes.mainContent}>
                                <main>
                                    {
                                        (bannerSettings && bannerSettings.banner_state === '1') &&
                                        <Span className={classes.BannerContainer}>
                                            <Typography className={classes.BannerText}>
                                                {bannerSettings.banner_text}
                                            </Typography>
                                        </Span>
                                        
                                    }
                                    {
                                        (overdueUsers && overdueUsers.overdue_state === '1') &&
                                    <Span className={classes.BannerContainer}>
                                            <Typography className={classes.BannerText}>
                                                Your account is on hold and sending is disabled until you pay your overdue invoice. 
                                            <Link to="/profile/full-billing-history" 
                                                className={classes.overdueUsersLink}
                                            >
                                                CLICK HERE
                                            </Link>
                                            </Typography>
                                        </Span>
                                    }
                                    {children}
                                </main>
                            </Span>
                        </UserAccountContext.Provider>
                    </React.Fragment>
                    : <Loader />
            }
        </React.Fragment>
    );
}

export default withStyles(styles)(DashboardLayout);
