import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const CreateCampaign = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/CreateCampaign')
);

const ScheduledCampaigns = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/ScheduledCampaigns')
);

const SentCampaigns = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/SentCampaigns')
);

const NormalCampaignDetail = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/NormalCampaignDetail')
);

const DripCampaignDetail = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/DripCampaignDetail')
);

const Calendar = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/Calendar')
);

const EditCampaign = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/EditCampaign')
);

const ResendCampaign = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/ResendCampaign')
);

const InitiateCreateCampaign = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/InitiateCreateCampaign')
);

const FinishCampaign = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/FinishCampaign')
);

const CreateCampaignV3 = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/CreateCampaignV3')
);

const ResendCampaignV3 = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/ResendCampaignV3')
);

const CreateCampaignVS = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Campaigns/CreateCampaignVS')
);

function CampaignRoutes(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact={true}
                    path={`${match.path}/create-campaign`}
                    component={CreateCampaign}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Create New Campaign'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/scheduled-campaigns`}
                    component={ScheduledCampaigns}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Scheduled'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/sent-campaigns`}
                    component={SentCampaigns}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Sent'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/normal-campaign-detail/:id`}
                    component={NormalCampaignDetail}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Detail'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/drip-campaign-detail/:id`}
                    component={DripCampaignDetail}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Detail'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/calendar`}
                    component={Calendar}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Detail'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-campaign/:id`}
                    component={EditCampaign}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Edit'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/resend/:id`}
                    component={ResendCampaign}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Resend'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/initiate-campaign`}
                    component={InitiateCreateCampaign}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Initiate Campaign'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/finish-campaign`}
                    component={FinishCampaign}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Finish Campaign'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/create-campaign-v3`}
                    component={CreateCampaignV3}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Create Campaign V3'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/resendv3/:id`}
                    component={ResendCampaignV3}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Resend V3'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/create-campaign-vs`}
                    component={CreateCampaignVS}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Campaigns | Create Campaign VS'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>
    )
}

export default CampaignRoutes;