import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const CreateNormalTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/AddNormalTemplate')
);

const ManageNormalTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/ManageNormalTemplates')
);

const EditNormalTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/EditNormalTemplate')
);

const AddDripTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/AddDripTemplate')
);

const ManageDripTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/ManageDripTemplates')
);

const EditDripTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/UserTemplate/EditDripTemplate')
);

function AdvanceRoutes(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact={true}
                    path={`${match.path}/create-normal-template`}
                    component={CreateNormalTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Create Normal Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-normal-templates`}
                    component={ManageNormalTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Manage Normal Templates'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-normal-template/:id`}
                    component={EditNormalTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Edit Normal Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/create-drip-template`}
                    component={AddDripTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Add Drip Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-drip-templates`}
                    component={ManageDripTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Add Drip Template'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-drip-template/:id`}
                    component={EditDripTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Templates | Edit Drip Template'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>
    )
}

export default AdvanceRoutes;