import { getSessionToken } from '../../../utils/authentication/jwtUtils';
import { serviceRequest } from '../../apihelper';
import { API_ENDPOINTS } from '../apiendpoint';

export const getUserSubscriptionListAPI = (params, signal) => {
    let url = API_ENDPOINTS.getUserSubscriptionList;

    let requestOptions = {
        method: 'GET'
    }

    return serviceRequest(url, requestOptions);
};


export const userSignUpAPI = (params, signal) => {
    let url = API_ENDPOINTS.signUp;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params)
    }

    return serviceRequest(url, requestOptions);
};

export const addCreditCardAPI = (params, signal) => {
    let url = API_ENDPOINTS.chargeMyCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const confirmChargeCardAPI = (params, signal) => {
    let url = API_ENDPOINTS.confirmChargeMyCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const setTimezoneAPI = (params, signal) => {
    let url = API_ENDPOINTS.setTimeZone;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const setTermsAPI = (params, signal) => {
    let url = API_ENDPOINTS.setTerms;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const getNumbersAPI = (params, signal) => {
    let url = API_ENDPOINTS.getNumbersList+'?area_code='+params['area_code']+'&type='+params['type'];
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const buyNumberAPI = (params, signal) => {
    let url = API_ENDPOINTS.buyNumber;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const userSignInApi = (params, signal) => {
    let url = API_ENDPOINTS.signIn;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params)
    }

    return serviceRequest(url, requestOptions);
};

export const validateSessionTokenAPI = (params, signal) => {
    let url = API_ENDPOINTS.sessionToken;

    let requestOptions = {
        method: 'GET',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const ForgotPasswordAPI = (params) => {
    let url = API_ENDPOINTS.forgetPassword;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const ResetPasswordAPI = (params) => {
    let url = API_ENDPOINTS.ResetPassword;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
}

export const ValidateForgotLinkAPI = (params) => {
    let url = API_ENDPOINTS.validateForgotLink;
    
    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);

}

export const ChangePasswordAPI = (params) => {
    let url = API_ENDPOINTS.changePassword;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
}

export const VerifyAccountAPI = (params) => {
    let url = API_ENDPOINTS.VerifyAccount;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
}

export const CheckProviderTypeAPI = (params) => {
    let url = API_ENDPOINTS.checkProviderType;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);

}

export const GetUserBandwidthDetailsAPI = (params, signal) => {
    let url = API_ENDPOINTS.getUserBandwidthDetails;

    let requestOptions = {
        method: 'GET',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const GetBandwidthNumbersAPI = (params) => {
    let url = API_ENDPOINTS.getBandwidthNumbers+'?area_code='+params['area_code']+'&type='+params['type'];
    
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);

}

export const BuyBandwidthNumberAPI = (params) => {
    let url = API_ENDPOINTS.buyBandwidthNumbers;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);

}

export const userSignUpPinkTelAPI = (params, signal) => {
    let url = API_ENDPOINTS.signUpPinkTel;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params)
    }

    return serviceRequest(url, requestOptions);
};

export const getUserSubscriptionListPinkTelAPI = (params, signal) => {
    let url = API_ENDPOINTS.getUserSubscriptionListPinkTel;

    let requestOptions = {
        method: 'GET'
    }

    return serviceRequest(url, requestOptions);
};

export const addPinkCreditCardAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkChargeMyCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkConfirmChargeCardAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkConfirmChargeMyCard;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkSetTimezoneAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkSetTimeZone;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkSetTermsAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkSetTerms;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkBuyNumberAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkBuyNumber;

    let requestOptions = {
        method: 'POST',
        body:JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkGetNumbersListAPI = (params, signal) => {
    let url = API_ENDPOINTS.pinkGetNumbersList+'?area_code='+params['area_code']+'&type='+params['type'];
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const usersLogoutAPI = (params, signal) => {
    let url = API_ENDPOINTS.usersLogout;

    let requestOptions = {
        method: 'DELETE',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }
    
    return serviceRequest(url, requestOptions);
};

export const pinkUserTwoFactorAPI = (params) => {
    let url = API_ENDPOINTS.sendPinkTwoFactorCode;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

};

export const verifyPinkTwoFactorCodeAPI = (params) => {
    let url = API_ENDPOINTS.verifyPinkTwoFactorCode;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

};

export const pinkUserWizardAPI = (params) => {
    let url = API_ENDPOINTS.pinkUserWizard;

    let requestOptions = {
        method:'POST',
        body: JSON.stringify(params),
        headers:{
            'session-token' : getSessionToken('session_token') 		   
        }
    }
    
    return serviceRequest(url, requestOptions)

}

export const getDefaultTollFreeNumbersAPI = (params, signal) => {
    let url = API_ENDPOINTS.getDefaultTollFreeNumbers;
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const getUserDetailsAPI = (params) => {
    let url = API_ENDPOINTS.getUserData+'?user_id='+params['user_id'];
    let requestOptions = {
        method: 'GET',
        headers:{
            'session-token' : getSessionToken('session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};