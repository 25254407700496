import React from 'react'
import { Switch } from 'react-router-dom'
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from '../../layouts/DashboardLayout/';
import Loader from '../../helper/loaders';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const AddNormalFolder = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddNormalFolder')
);

const AddNormalCategory = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddNormalCategory')
);

const ManageNormalFolder = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageNormalFolder')
);

const ManageNormalCategories = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageNormalCategory')
);

const AddDripFolder = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddDripFolder')
);

const ManageDripFolder = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageDripFolder')
);

const AddDripCategory = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddDripCategory')
);

const ManageDripCategory = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageDripCategory')
);

const AddNormalTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddNormalTemplate')
);

const AddDripTemplate = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/AddDripTemplate')
);

const ManageNormalTemplates = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageNormalTemplates')
);

const ManageDripTemplates = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/ManageDripTemplates')
);

const DripTemplateEdit = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/DripTemplateEdit')
);

const NormalTemplateEdit = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/NormalTemplateEdit')
);

const ManageBirthdays = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/UpdateBirthdayMessage')
);

const ManageUsers = Loader(()=>
    import(/* webpackChunkName: "NotFoundPage" */  '../../containers/Manager/ManageUsers')
);

const OverdueUsers = Loader(() =>
import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Manager/OverdueUsers')
);

function ManagerRoutes(props) {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    exact
                    path={`${match.path}`}
                    component={()=><h3>Manager Page</h3>}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                />

                {/* Normal Template Routes */}
                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-normal-template`}
                    component={ManageNormalTemplates}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Normal Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-normal-template`}
                    component={AddNormalTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Normal Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-normal-category`}
                    component={AddNormalCategory}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Normal Category'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-normal-folder`}
                    component={AddNormalFolder}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Normal Folder'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-normal-folder`}
                    component={ManageNormalFolder}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Normal Folder'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-normal-category`}
                    component={ManageNormalCategories}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Normal Category'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-normal-template/:id`}
                    component={NormalTemplateEdit}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Edit Normal Template'
                />

                {/* Drip Template Routes */}
                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-drip-template`}
                    component={ManageDripTemplates}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Drip Templates'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-drip-template`}
                    component={AddDripTemplate}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Drip Template'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-drip-category`}
                    component={AddDripCategory}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Drip Category'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/add-drip-folder`}
                    component={AddDripFolder}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Add Drip Folder'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-drip-folder`}
                    component={ManageDripFolder}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Drip Folder'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-drip-category`}
                    component={ManageDripCategory}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Drip Category'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/edit-drip-template/:id`}
                    component={DripTemplateEdit}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Edit Drip Template'
                />
                
                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-birthdays/:id`}
                    component={ManageBirthdays}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Update Birthdays'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/manage-users`}
                    component={ManageUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Manage Users'
                />

                <AppRoute 
                    exact={true}
                    path={`${match.path}/overdue-users`}
                    component={OverdueUsers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='private'
                    title='Manager | Overdue Users'
                />


                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />
            </Switch>
        </React.Fragment>
    )
}

export default ManagerRoutes;
